export const DEBUGGER_STATES = {
  START: 'start',
  DONE: 'done',
  ERROR: 'error',
};
export const COOKIE_NAMES = {
  SID: 'sid',
  EUCONSENT: 'euconsent-v2',
};
export const RETARGETING_BASE_URL = 'https://c.seznam.cz/retargeting';
export const CONVERSION_BASE_URL = 'https://c.seznam.cz/conv';
export const ZBOZI_BASE_URL = 'https://www.zbozi.cz/conversion/js/conv';

export const CONVERSION_HIT_ID_LENGTH = 75;
