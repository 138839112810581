/**
 * Returns value of the cookie with provided name
 * @param {String} name Name of the cookie
 * @returns {String} Cookie value or empty string
 */
export const getCookieValue = (name) => {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=', 2);

  return parts.length === 2 ? parts.pop().split(';').shift() : '';
};
