import { getCookieValue } from './utils/getCookieValue';
import { getRetargetingUrl } from './utils/getRetargetingUrl';

import { RETARGETING_BASE_URL, COOKIE_NAMES } from './constants';
import { setConsent, getVerifiedConsentValue } from './consentManager';
import { getIdentityObject } from './utils/getIdentityObject';

import { HitSender } from './HitSender/HitSender';
import IS from '@iva/identity-storage';
import { SID_UTILS } from '@iva/utils';

/**
 * Sends retargeting hit with provided data.
 * @param {Object} conf Data for the retargeting.
 * @param {Number} conf.rtgId Retargeting ID, Example = 123
 * @param {String} [conf.category] Eshop categories, Example="Zahrada | Stínící technika | Zahradní slunečníky"
 * @param {String} [conf.itemId]  Offer ID, Example = "shovel"
 * @param {String} [conf.pageType] Page type, Example = "eshop"
 * @param {String} [conf.rtgUrl] Current URL (must match window.location.href)
 * @param {String | String[]} [conf.productIds] Products ID, Uses internal services (partners do not send)
 * @param {Number} [conf.consent] Consent from the visitor to submit a retargeting hit, Example = 0 (není souhlas) nebo 1 (je souhlas)
 * @param {Number} [conf.said] Cookie rus id. Uses internal services (partners do not send). There is no way to set it in window, it can be passed only when calling the function retargetingHit
 * @param {String} [conf.eid] hashed email address
 *
 * @param {Function} debug Debugger function from the script calling this function.
 * @return {Boolean} Indicator (true - retargetingHit sent , false - retargetingHit not sent)
 */
export const retargetingHit = (conf, debug = () => {}) => {
  if (!conf || !conf.rtgId) {
    debug('error', 'RetargetingHit has not been sent. No required fields "conf" or "conf.rtgId".');
    return;
  }

  const { rtgId, category, itemId, pageType, rtgUrl, productIds, consent, said, eid } = conf;
  setConsent(consent);

  const { internal } = window.rc || {};
  const euconsentV2 = internal && getCookieValue(COOKIE_NAMES.EUCONSENT);
  const sidCookie = getCookieValue(COOKIE_NAMES.SID);
  const sid = (consent || internal) && SID_UTILS.isValidSid(sidCookie) ? sidCookie : '';
  if (eid) {
    // adding email eid to IdentityObject
    IS.updateIdentities({ eid });
  } else {
    IS.clearIdentities(['eid']);
  }
  const ids = getIdentityObject();

  debug({
    message: 'got following retargeting data:',
    rtgId,
    category,
    itemId,
    pageType,
    rtgUrl,
    productIds,
    euconsentV2,
    sid,
    consent,
    said,
    ids,
  });

  const consentValue = internal ? null : getVerifiedConsentValue(consent);

  const getURL = () => {
    debug({ message: 'Generating retargeting URL', internal, category, rtgUrl });
    const finalRetURL = getRetargetingUrl(rtgUrl, category, internal);
    debug({ message: 'Final retargeting URL', finalRetURL });
    return finalRetURL;
  };

  new HitSender(RETARGETING_BASE_URL, debug)
    .appendValue('id', rtgId)
    .appendValue('category', category)
    .appendValue('itemId', itemId)
    .appendValue('url', getURL())
    .appendValue('pageType', pageType)
    .appendValue('euconsent', euconsentV2)
    .appendValue('dsid', sid)
    .appendArray('productIds', productIds)
    .appendValue('consent', consentValue)
    .appendValue('said', said)
    .appendObject('ids', ids)
    .send();

  return true;
};
