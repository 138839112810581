// IVA libraries
import '@iva/refresher';
import { handleSbrowserSid } from '@iva/sbrowser-sid';
import { startErrorHandling } from '@iva/error-reporter';

// Modules
import { retargetingDebug as debug } from 'Modules/debug.js';
import { initConsentLogicInWindow } from 'Modules/consentManager';
import { retargetingHit } from 'Modules/retargetingHit';
import { getRetargetingData } from 'Modules/utils/getRetargetingData';
import { handleSid } from 'Modules/handleSid';

// Constants
import { DEBUGGER_STATES } from 'Modules/constants.js';

startErrorHandling({
  endpoint: 'sklik-ap-static',
  scriptName: 'retargeting.js',
});

debug({ message: 'retargeting.js started', state: DEBUGGER_STATES.START });

window.rc = window.rc || {};
window.rc.retargetingHit = retargetingHit;
initConsentLogicInWindow(debug);

if (!handleSbrowserSid()) {
  handleSid();
}

// retargetingHit
const retargetingConf = getRetargetingData();
debug({ message: 'retargeting got this data:', ...retargetingConf });
if (retargetingHit(retargetingConf, debug)) {
  // eslint-disable-next-line camelcase
  window.seznam_dispatchedRetargetingIds = window.seznam_dispatchedRetargetingIds || [];
  window.seznam_dispatchedRetargetingIds.push(retargetingConf.rtgId);
}

debug({ message: 'retargeting.js finished', state: DEBUGGER_STATES.DONE });
